import React from "react";
import Register from "../component/Register";

function Home() {
    return ( <div className="AppHome">        
        <Register></Register>
    </div>
    );
}

export default Home;